import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getArtist, API_PATH } from '../api/api';
import parseHtml from 'html-react-parser';
import { Artist } from '../api/artist';
import ArtistGallery from '../components/ArtistGallery'
import { Loading, MajError } from '../components/Loading';
import { SocialLink } from '../components/SocialLink';

import './ArtistPage.scss';

import artistImage from '../assets/mock_artist-image.jpg';

const USE_MOCK = process.env.REACT_APP_USE_MOCK;

function ArtistPage() {
    const { id } = useParams() as { id: string };
    const [artist, setArtist] = useState<Artist>();
    const [error, setError] = useState<MajError>({ code: undefined, string: undefined });

    useEffect(() => {
        getArtist(id)
            .then((response) => {
                setArtist(response.data);
            })
            .catch((error) => {
                console.error(error);
                setError({ code: 2, string: 'Felkod 2, kan inte ladda den valda konstnären, maila felkod och kommentar till developer@kvasarnomad.net' });
            });
    }, [id]);

    return (
        <div className="ArtistPage">
            {artist ? (<>
                <div className="artist-image-header">
                    <div className='artist-image-container'>
                        {USE_MOCK ?
                            (<img src={artistImage} alt='Mock Bild' />)
                            :
                            (<img src={`${API_PATH}/artists/image/${artist.artist_image_url}`} alt="Bild på konstnär" />)
                        }
                    </div>
                </div>
                <h2 className="artist-name">{artist.name}</h2>
                <div className='artist-exhibitionaddress-header'>
                    <h3>Ateljéadress</h3>
                    <h4>{artist.exhibitionaddress.street}, {artist.exhibitionaddress.town}</h4>
                </div>
                <div className="artist-text">{parseHtml(`${artist.textbody}`)}</div>
                {artist.instagram ? (<SocialLink type='instagram' username={artist.instagram}></SocialLink>) : (null)}
                {artist.facebook ? (<SocialLink type='facebook' username={artist.instagram}></SocialLink>) : (null)}
                <ArtistGallery artist_id={artist._id}></ArtistGallery>
            </>)
                : (<Loading error={error} />)
            }
        </div >
    );
}

export default ArtistPage;
